import React from 'react';
import ScrollNext from '../ScrollNext';

const MainSection = () => {
  return (
    <section
      id="sduf-project"
      className="relative flex flex-col items-center justify-center h-screen bg-gray-900 text-white"
    >
      <div className="relative z-20 text-center max-w-4xl mx-auto">
        <h1 className="text-5xl md:text-7xl font-bold">Server Driven UI Framework</h1>
        <p className="mt-4 text-lg md:text-2xl tracking-wide">
          Inspired by Airbnb’s approach, this framework provides a versatile admin panel that empowers
          non-technical users to design and adjust app components in real time. This approach accelerates 
          development and boosts adaptability.
        </p>
      </div>

      <div className="absolute inset-0 z-1">
        <div
          className="absolute inset-0 bg-gradient-to-b from-transparent to-[#1A1A1A]"
          style={{
            backgroundImage: `url('/sduf/constructor.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            opacity: 0.06,
          }}
        />
      </div>

      <ScrollNext to="screens" />
    </section>
  );
};

export default MainSection;
