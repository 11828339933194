import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';

const Header = () => {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className={`w-full px-4 py-4 md:p-4 h-14 z-10 transition-all duration-300 ${isSticky ? 'fixed top-0 left-0 bg-black shadow-lg z-30' : 'absolute bg-transparent'}`}>
            <div className="flex justify-between items-center max-w-screen-xl mx-auto">
                <div className="text-teal-400 text-lg md:text-xl font-mono">
                    <h1>
                        <NavLink to={'/'} className="cursor-pointer">
                            DmytroKozin<span className="text-indigo-400">.</span>
                        </NavLink>
                    </h1>
                </div>
                <nav className="text-sm font-mono flex space-x-4 md:space-x-8 hidden lg:block">
                    <Link to={'sduf-project'}
                        smooth={true}
                        duration={500}
                        className="cursor-pointer text-gray-400 hover:text-white">
                        {'// index'}
                    </Link>
                    <Link to={'screen-0'}
                        smooth={true}
                        duration={500}
                        className="cursor-pointer text-gray-400 hover:text-white">
                        {'// home page'}
                    </Link>
                    <Link to={'screen-1'}
                        smooth={true}
                        duration={500}
                        className="cursor-pointer text-gray-400 hover:text-white">
                        {'// configuration'}
                    </Link>
                    <Link to={'screen-2'}
                        smooth={true}
                        duration={500}
                        className="cursor-pointer text-gray-400 hover:text-white">
                        {'// showroom'}
                    </Link>
                    <Link to={'screen-3'}
                        smooth={true}
                        duration={500}
                        className="cursor-pointer text-gray-400 hover:text-white">
                        {'// ui studio'}
                    </Link>
                    <Link to={'screen-4'}
                        smooth={true}
                        duration={500}
                        className="cursor-pointer text-gray-400 hover:text-white">
                        {'// result'}
                    </Link>
                </nav>
            </div>
        </header>
    );
};

export default Header;
