

import React from 'react';
import { Link } from 'react-scroll';

const ScrollNext = ({ to, className }) => {
    return (
        <Link
            to={to}
            smooth={true}
            duration={500}
            className={`${className} cursor-pointer absolute bottom-8`}
        >
            <div className="absolute bottom-8">
                <div className="animate-bounce">
                    <span className="text-4xl">&darr;</span>
                </div>
            </div>
        </Link>

    );
};

export default ScrollNext;
