import React from 'react';
import ExpertiseSection from '../components/main/ExpertiseSection';
import MainSection from '../components/main/MainSection';
import Header from '../components/main/Header';
import MyWorkSection from '../components/main/MyWorkSection';
import ProfessionalExperienceSection from '../components/main/ProfessionalExperienceSection';
import FreelanceOpportunitiesSection from '../components/main/FreelanceOpportunitiesSection';
import ScrollUpButton from '../components/ScrollUpButton';

function MainPage() {
  return (
    <div>
      <Header />
      <MainSection />
      <ExpertiseSection />
      <MyWorkSection />
      <ProfessionalExperienceSection />
      <FreelanceOpportunitiesSection />
      <ScrollUpButton />
    </div>
  );
}

export default MainPage;
