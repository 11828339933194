// import './App.css';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';

function RootTemplate() {
  return (
    <div className="App">
      <Outlet />
      <Footer/>
    </div>
  );
}

export default RootTemplate;
