import React, { useState } from 'react';

const ProfessionalExperienceSection = () => {
    const experiences = [
        {
            title: "Software Developer",
            company: "AwareAds",
            location: "Toronto",
            date: "Jun 2023 - Now",
            description: `
            • Implemented two-factor authentication (2FA) through SMS and Google Authenticator, significantly improving the system's security and protecting user accounts.
            • Designed and developed a complex forms allowing the creation of multiple entities simultaneously, featuring modes like super bulk paste and shortcuts, streamlining data entry processes and improving user efficiency.
            • Built a robust postback service to enhance system communication and ensure accurate data tracking and updates across services.
            • Optimized database queries, resulting in a noticeable speed increase and further improving system performance.
            • Created and integrated a referral system, driving user engagement and customer acquisition through incentivized referrals.
            • Proposed and implemented front-end caching strategies, resulting in a noticeable increase in system speed and user experience.
            • Suggested and implemented encryption for sensitive data, significantly enhancing the overall security of the system and ensuring compliance with best practices.
            • Achieved 70% code coverage with unit tests, significantly improving the system's reliability and ensuring better detection of potential issues.
            • Implemented a CI/CD pipeline to automatically run tests when a pull request (PR) is created, improving code quality and reducing the time for code reviews and merges.
            `,
            techEnvironment: ["PHP", "HTML5", "MYSQL", "AWS", "RabbitMQ", "Docker", "React"]
        },
        {
            title: "Software Developer",
            company: "Roobykon Software",
            location: "Ukraine",
            date: "Apr 2022 - Jun 2023",
            description: `
            • Worked as part of software development teams in the design, development, and support of both new and existing software products and systems;
            • Monitored issues/problems, and provided technical guidance and support to team members, resulting in the development of skilled and productive software development teams. 
            • Developed script which allows reduce time to implement features by 15%
            • Implemented telemetry and monitored existing system using Elixir, allowing real-time analysis of user actions, increasing 30% reaction speed, which led to informed decision-making and improvements in overall user experience.
            `,
            techEnvironment: ["Elixir", "Phoenix", "PHP", "Symphony", "HTML5", "PostgreSQL", "Redis", "RabbitMQ", "Docker"]
        },
        {
            title: "Full Stack Developer",
            company: "RIA.com Marketplaces",
            location: "Ukraine",
            date: "Dec 2019 - Apr 2022",
            description: `
            • Implemented an automated document exchange system between departments, resulting in streamlined collaboration between teams and faster decision-making processes.
            • Developed financial reports using PHP and conducted analyses that provided management with valuable insights into revenue growth, expense trends, and cash flow management.
            • Successfully mentored and onboarded 2 junior developers, resulting in their seamless integration into the team and increased productivity.
            • Integrated company system with CRM, boosting interdepartmental communication and data sharing for sales team efficiency and valuable insights.
            • Implemented voice-based password recovery, reducing support tickets and improving resource allocation using PHP and Elixir.
            • Migrated 2.4 million chat messages to Cassandra DB and optimized conversation loading time, resulting in a 3x improvement in system performance.
        `,
            techEnvironment: ["Elixir", "Phoenix", "PHP", "Yii", "Zend", "HTML5", "AngularJS", "MySQL", "Cassandra", "Redis", "RabbitMQ", "Docker", "Kubernetes", "ClickHouse", "Nginx"]
        },
        {
            title: "Front End Developer",
            company: "RegulusTeam",
            location: "Ukraine",
            date: "Aug 2019 - Dec 2019",
            description: `
            • Implemented an efficient design and developed user interaction screens using HTML, CSS, Bootstrap, JavaScript, Angular, resulting in a highly functional and visually appealing website. 
            • Implemented AJAX to improve website functionality and streamline information exchange between browsers and servers.
            `,
            techEnvironment: ["Angular", "HTML5", "jQuery"]
        },
        {
            title: "Full Stack Developer Intern",
            company: "INTITA",
            location: "Ukraine",
            date: "Mar 2018 - Dec 2019",
            description: `
            • Collaborated with backend and client engineers to help build features and solve creative problems. 
            • Implemented and delivered 15 new features within a tight deadline, resulting in an increase in client satisfaction and retention. These features were developed using PHP, Laravel, Javascript, VueJs, HTML5, and CSS, and were designed to be cross-browser and mobile compatible.
            `,
            techEnvironment: ["PHP", "Laravel", "jQuery", "HTML5", "Javascript", "VueJS", "MySQL"]
        }
    ];

    const [openIndex, setOpenIndex] = useState(null);

    const toggleOpen = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <section id="experience" className="bg-black text-white min-h-screen py-16">
            <div className="container mx-auto p-5">
                <h1 className="text-4xl md:text-5xl font-bold mb-12 text-center">Professional Experience</h1>
                {experiences.map((experience, index) => (
                    <div key={index} className="mb-6">
                        <div
                            className={`bg-purple-600 p-4 rounded-lg cursor-pointer ${openIndex === index ? 'rounded-b-none' : ''
                                }`}
                            onClick={() => toggleOpen(index)}
                        >
                            <div className="flex justify-between items-center">
                                <h2 className="text-xl font-bold">{experience.title} @ {experience.company}</h2>
                                <p className="text-gray-200">{experience.date}</p>
                            </div>
                            {openIndex === index && (
                                <div className="mt-4 bg-purple-700 p-4 rounded-b-lg">
                                    <p className="mb-4 whitespace-pre-line">{experience.description}</p>
                                    <div className="flex flex-wrap gap-2">
                                        {experience.techEnvironment.map((tech, i) => (
                                            <span
                                                key={i}
                                                className="bg-blue-600 text-white rounded-full px-3 py-1 text-sm font-semibold"
                                            >
                                                {tech}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default ProfessionalExperienceSection;
