import React, { useState } from 'react';
import Modal from './Modal';

const ScreensSection = () => {
    const [modalImage, setModalImage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = (src, alt) => {
        setModalImage({ src, alt });
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalImage(null);
    };

    const screens = [
        {
            src: '/sduf/home_screen.png',
            alt: 'Home Page',
            title: 'Home Page',
            description: 'Showcases the home screen of the application, featuring a clean and intuitive interface. Users are greeted with a minimalistic design, allowing for easy navigation.',
        },
        {
            images: [
                { src: '/sduf/generate_build.png', alt: 'Trigger Build', title: 'Trigger Build' },
                { src: '/sduf/logs.png', alt: 'Build Progress', title: 'Build Progress' },
            ],
            alt: 'Configuration Management',
            title: 'Configuration Management',
            description: 'The Configuration Section provides a comprehensive interface for managing your build processes. Adjust various settings and parameters, trigger new builds with a single click, monitor build progress with real-time updates, and access detailed build logs for in-depth analysis and troubleshooting. Designed to streamline your development workflow and enhance efficiency.',
        },
        {
            src: '/sduf/component_showroom.png',
            alt: 'Component Showroom',
            title: 'Component Showroom',
            description: 'The Component Showroom page offers a dynamic and interactive experience for exploring the various UI components available within the application. Users can browse through a curated list of components, customize their configurations in real-time, and observe immediate feedback on how changes affect each component. The page allows users to engage with interactive demonstrations, providing a hands-on approach to understanding and utilizing the components effectively within the application.'
        },
        {
            src: '/sduf/constructor.png',
            alt: 'Dynamic UI Configuration',
            title: 'Dynamic UI Configuration',
            description: 'Demonstrates the server-driven UI configuration capabilities, where users can dynamically adjust the layout and content of the screen via a web panel. The flexibility and adaptability of the UI are highlighted.',
        },
        {
            src: '/sduf/mobile.jpg',
            alt: 'Mobile Result',
            title: 'Real Mobile Result',
            description: 'Displays the final result of the application on a mobile device. This section provides a real-world view of how the application looks and functions on an actual mobile device, demonstrating the polished and user-friendly interface delivered to end-users.',
        },
    ];

    return (
        <div id="screens">
            {screens.map((screen, index) => (
                <section
                    key={index}
                    id={`screen-${index}`}
                    className="relative flex flex-col items-center justify-center min-h-screen bg-black text-white"
                    style={{ backgroundColor: '#100D0E' }}
                >
                    <div className="relative z-20 flex flex-col items-center px-6 md:px-12 lg:px-24 py-16 md:py-24 text-center max-w-4xl mx-auto">
                        {screen.images ? (
                            <div className="flex flex-col md:flex-row gap-8 md:gap-12 mb-8">
                                {screen.images.map((image, imgIndex) => (
                                    <div key={imgIndex} className="flex-1 cursor-pointer" onClick={() => openModal(image.src, image.alt)}>
                                        <img
                                            src={image.src}
                                            alt={image.alt}
                                            className="w-full h-auto max-w-xs md:max-w-md lg:max-w-lg rounded-lg shadow-2xl"
                                        />
                                        <h3 className="text-xl md:text-2xl font-semibold mt-4">{image.title}</h3>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <img
                                src={screen.src}
                                alt={screen.alt}
                                className="max-h-96 max-w-xs md:max-w-lg lg:max-w-3xl h-auto rounded-lg shadow-2xl mb-6 cursor-pointer"
                                onClick={() => openModal(screen.src, screen.alt)}
                            />
                        )}
                        <h2 className="text-3xl md:text-4xl lg:text-5xl font-extrabold mb-4">{screen.title}</h2>
                        <p className="text-base md:text-lg lg:text-xl leading-relaxed text-gray-300">
                            {screen.description}
                        </p>
                    </div>
                </section>
            ))}
            <Modal
                isOpen={modalOpen}
                onClose={closeModal}
                src={modalImage?.src}
                alt={modalImage?.alt}
            />
        </div>
    );
};

export default ScreensSection;
