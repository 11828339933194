const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <footer className="bg-black text-white py-4 text-center">
            <div className="container mx-auto">
                <p className="text-sm md:text-base">
                    © {currentYear} Dmytro Kozin. All Rights Reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
