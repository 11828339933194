import React from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isOpen, onClose, src, alt }) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50" onClick={onClose}>
            <div className="relative bg-white p-4 md:p-6 rounded-lg max-w-full max-h-[90vh] overflow-auto" onClick={e => e.stopPropagation()}>
                <button className="absolute top-2 right-2 text-2xl md:text-3xl font-bold text-gray-600 hover:text-gray-800" onClick={onClose}>×</button>
                <img src={src} alt={alt} className="w-full h-auto max-h-[80vh] object-contain rounded-lg" />
            </div>
        </div>,
        document.body
    );
};

export default Modal;
