import React, { useEffect, useState } from 'react';

function ScrollUpButton() {
  const [showScrollUpButton, setShowScrollUpButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowScrollUpButton(true);
      } else {
        setShowScrollUpButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div>
      {showScrollUpButton && (
        <button
          onClick={scrollToTop}
          className="hidden md:block fixed bottom-10 right-10 p-5 bg-white text-black rounded-full shadow-lg hover:bg-purple-400 focus:outline-none"
        >
          ↑
        </button>
      )}
    </div>
  );
}

export default ScrollUpButton;
