import React from 'react';
import ScrollNext from '../ScrollNext';

const ExpertiseSection = () => {
    return (
        <section id="expertise" className="relative flex flex-col items-center justify-center h-screen bg-black text-white py-16">
            <div className="text-center mb-12 z-10">
                <h2 className="text-4xl md:text-6xl lg:text-8xl font-bold">My Expertise</h2>
            </div>
            <div className="flex flex-col sm:flex-row justify-center items-stretch sm:space-x-6 max-w-screen-md z-10 space-y-8 sm:space-y-0 p-5">
                {/* Software Development Card */}
                <div className="border border-gray-700 p-8 rounded-lg text-left w-full sm:w-1/3 flex flex-col h-full">
                    <h3 className="text-xl font-bold">
                        <span className="text-white">Software</span> <span className="underline decoration-pink-500">Development</span>
                    </h3>
                    <p className="mt-4 text-gray-400 flex-grow">
                        Passionate about building high-quality software, with extensive experience in both functional and OOP: PHP, Elixir, JavaScript.
                    </p>

                </div>

                {/* Frontend Development Card */}
                <div className="border border-gray-700 p-8 rounded-lg text-left w-full sm:w-1/3 flex flex-col h-full">
                    <h3 className="text-xl font-bold">
                        <span className="text-white">Frontend Dev</span> <span className="underline decoration-blue-500">React, Vue, Angular</span>
                    </h3>
                    <p className="mt-4 text-gray-400 flex-grow">
                        Over 5 years of development experience in HTML, CSS, JS, React, Angular, and Vue frameworks.
                    </p>
                </div>

                {/* React Native Development Card */}
                <div className="border border-gray-700 p-8 rounded-lg text-left w-full sm:w-1/3 flex flex-col h-full">
                    <h3 className="text-xl font-bold">
                        <span className="text-white">React Native Dev</span> <span className="underline decoration-orange-500">Android</span>
                    </h3>
                    <p className="mt-4 text-gray-400 flex-grow">
                        Skilled in developing hybrid mobile apps and cross-platform solutions using the React Native framework.
                    </p>
                </div>
            </div>

            <div
                className="absolute inset-0 flex justify-center items-center opacity-30"
            >
                <img
                    src="/expertise.png"
                    alt="Background"
                    className="sm:max-w-3xl mx-auto"
                />
            </div>

            <ScrollNext to="work" className="hidden sm:block" />
        </section>


    );
};

export default ExpertiseSection;


